* {
  font-size: inherit;
  margin: 0;
  padding: 0;
}

body {
  padding: 2rem;
}

body * + * {
  margin-top: 1rem;
}

.adyen-checkout__spinner__wrapper {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.adyen-checkout__spinner__wrapper--inline {
  height: auto;
  margin-right: 8px;
  display: inline-block;
}

[dir="rtl"] .adyen-checkout__spinner__wrapper--inline {
  margin-left: 8px;
  margin-right: 0;
}

.adyen-checkout__spinner {
  height: 43px;
  width: 43px;
  border: 3px solid #06f;
  border-top-color: #0000;
  border-radius: 50%;
  animation: rotateSpinner 1.5s linear infinite;
}

.adyen-checkout__spinner--large {
  height: 43px;
  width: 43px;
}

.adyen-checkout__spinner--small {
  height: 16px;
  width: 16px;
  border-width: 2px;
}

.adyen-checkout__spinner--medium {
  height: 28px;
  width: 28px;
}

@keyframes rotateSpinner {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.adyen-checkout__button {
  color: #fff;
  cursor: pointer;
  height: 48px;
  width: 100%;
  background: #00112c;
  border: 0;
  border-radius: 6px;
  margin: 0;
  padding: 15px;
  font-size: 1em;
  font-weight: 500;
  text-decoration: none;
  transition: background .3s ease-out, box-shadow .3s ease-out;
}

.adyen-checkout__button:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #99c2ff;
}

.adyen-checkout__button:hover {
  background: #1c3045;
  box-shadow: 0 0, 0 2px 4px -1px #0003, 0 4px 5px #00000024;
}

.adyen-checkout__button:active {
  background: #3a4a5c;
}

.adyen-checkout__button:hover:focus {
  box-shadow: 0 0 0 2px #99c2ff, 0 3px 4px #000f2d33;
}

.adyen-checkout__button:disabled, .adyen-checkout__button:disabled:hover {
  box-shadow: none;
  cursor: not-allowed;
  opacity: .4;
  -webkit-user-select: all;
  user-select: all;
}

.adyen-checkout__button.adyen-checkout__button--loading {
  box-shadow: none;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  background: #687282;
}

.adyen-checkout__button.adyen-checkout__button--pay {
  margin-top: 24px;
}

.adyen-checkout__button.adyen-checkout__button--pay:disabled {
  opacity: 1;
}

.adyen-checkout__button.adyen-checkout__button--standalone {
  margin-top: 0;
}

.adyen-checkout__button.adyen-checkout__button--inline {
  height: auto;
  width: auto;
  padding: 10px 8px;
  font-size: .81em;
  display: block;
}

.adyen-checkout__button.adyen-checkout__button--ghost {
  color: #00112c;
  background: none;
  border: 0;
}

.adyen-checkout__button.adyen-checkout__button--ghost:hover {
  box-shadow: none;
  background: #f7f8f9;
}

.adyen-checkout__button.adyen-checkout__button--ghost:active {
  box-shadow: none;
  background: #e6e9eb;
}

.adyen-checkout__button.adyen-checkout__button--secondary {
  color: #00112c;
  background: #fff;
  border: 1px solid #00112c;
  padding: 10px 12px;
}

.adyen-checkout__button.adyen-checkout__button--secondary:hover {
  background: #f7f8f9;
  box-shadow: 0 2px 4px #1b2a3c33, 0 4px 5px #1b2a3c24;
}

.adyen-checkout__button.adyen-checkout__button--secondary:active, .adyen-checkout__button.adyen-checkout__button--secondary:active:hover {
  box-shadow: none;
  background: #f7f8f9;
}

.adyen-checkout__button.adyen-checkout__button--secondary:disabled, .adyen-checkout__button.adyen-checkout__button--secondary:disabled:hover {
  box-shadow: none;
  cursor: not-allowed;
  opacity: .5;
  -webkit-user-select: all;
  user-select: all;
  background-color: #f7f8f9;
  border-color: #99a3ad;
}

.adyen-checkout__button.adyen-checkout__button--secondary .adyen-checkout__spinner {
  border-color: #0000 #00112c #00112c;
}

.adyen-checkout__button.adyen-checkout__button--action {
  color: #06f;
  background: #0066ff1a;
  border: 1px solid #0000;
  padding: 10px 12px;
}

.adyen-checkout__button.adyen-checkout__button--action:hover {
  box-shadow: none;
  background: #06f3;
}

.adyen-checkout__button.adyen-checkout__button--action:active, .adyen-checkout__button.adyen-checkout__button--action:active:hover {
  box-shadow: none;
  background: #0066ff4d;
}

.adyen-checkout__button.adyen-checkout__button--link {
  color: #06f;
  background: none;
  border: 1px solid #0000;
  border-radius: 3px;
  padding: 2px;
  font-weight: 400;
}

.adyen-checkout__button.adyen-checkout__button--link:hover {
  box-shadow: none;
  background: none;
  text-decoration: underline;
}

.adyen-checkout__button.adyen-checkout__button--completed, .adyen-checkout__button.adyen-checkout__button--completed:active, .adyen-checkout__button.adyen-checkout__button--completed:active:hover, .adyen-checkout__button.adyen-checkout__button--completed:hover {
  color: #fff;
  background: #0abf53;
}

.adyen-checkout__button.adyen-checkout__button--completed .adyen-checkout__button__icon {
  filter: brightness(0) invert();
}

.adyen-checkout__button__content {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.adyen-checkout__button__icon {
  margin-right: 12px;
}

[dir="rtl"] .adyen-checkout__button__icon {
  margin-left: 12px;
  margin-right: 0;
}

.adyen-checkout__button__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: center;
  display: block;
  overflow: hidden;
}

.adyen-checkout__button .adyen-checkout__spinner {
  border-color: #0000 #fff #fff;
}

.adyen-checkout__fieldset {
  width: 100%;
  padding-bottom: 8px;
  display: block;
}

.adyen-checkout__fieldset:last-of-type {
  padding-bottom: 0;
}

.adyen-checkout__fieldset + .adyen-checkout__fieldset {
  margin-top: 16px;
}

.adyen-checkout__fieldset__title {
  color: #687282;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  padding: 0 0 12px;
  font-size: .68em;
  font-weight: 700;
  display: block;
}

.adyen-checkout__field-group, .adyen-checkout__fieldset__fields {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.adyen-checkout__field-group:last-of-type .adyen-checkout__field {
  margin-bottom: 0;
}

.adyen-checkout__fieldset--readonly .adyen-checkout__fieldset__fields {
  color: #00112c;
  margin: 0;
  font-size: .81em;
  line-height: 19px;
}

.adyen-checkout__field {
  width: 100%;
  margin-bottom: 16px;
  display: block;
}

.adyen-checkout__field:last-of-type {
  margin-bottom: 0;
}

.adyen-checkout__label {
  display: block;
}

.adyen-checkout__helper-text, .adyen-checkout__label__text {
  color: #00112c;
  padding-bottom: 5px;
  font-size: .81em;
  font-weight: 400;
  line-height: 13px;
  display: block;
}

.adyen-checkout__helper-text {
  color: #687282;
}

.adyen-checkout__label__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: color .1s ease-out;
  display: block;
  overflow: hidden;
}

.adyen-checkout__label__text--error {
  color: #d10244;
}

.adyen-checkout__label--focused .adyen-checkout__label__text {
  color: #06f;
}

.adyen-checkout__error-text {
  color: #d10244;
  align-items: center;
  margin-top: 4px;
  font-size: .75em;
  font-weight: 400;
  display: flex;
}

.adyen-checkout__radio_group + .adyen-checkout-input__inline-validation {
  display: none;
}

.adyen-checkout__radio_group__input {
  opacity: 0;
  position: absolute;
}

.adyen-checkout__radio_group__label {
  color: inherit;
  padding-bottom: 0;
  padding-left: 24px;
  font-size: .81em;
  font-weight: 400;
  line-height: 16px;
  display: block;
  position: relative;
  overflow: visible;
}

.adyen-checkout__label--focused .adyen-checkout__radio_group__label {
  color: inherit;
}

.adyen-checkout__radio_group__label:before {
  content: "";
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #b9c4c9;
  border-radius: 50%;
  transition: border-color .2s ease-out, box-shadow .2s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.adyen-checkout__radio_group__label:hover:before {
  cursor: pointer;
  border-color: #99a3ad;
  box-shadow: 0 0 0 2px #d4d9db;
}

.adyen-checkout__radio_group__label:after {
  content: "";
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 auto;
  transition: transform .2s ease-out;
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  transform: scale(0);
  box-shadow: 0 1px 1px #000f2d40;
}

.adyen-checkout__radio_group__label:hover {
  cursor: pointer;
  border-color: #06f;
}

.adyen-checkout__radio_group__input:checked + .adyen-checkout__radio_group__label:before, .adyen-checkout__radio_group__label--selected {
  background-color: #06f;
  border: 0;
  transition: all .2s ease-out;
}

.adyen-checkout__radio_group__input:checked + .adyen-checkout__radio_group__label:after {
  transform: scale(1);
}

.adyen-checkout__radio_group__input:focus + .adyen-checkout__radio_group__label:before {
  border-color: #06f;
  box-shadow: 0 0 0 2px #06f6;
}

.adyen-checkout__radio_group__input:checked + .adyen-checkout__radio_group__label:hover:before, .adyen-checkout__radio_group__input:checked:active + .adyen-checkout__radio_group__label:before, .adyen-checkout__radio_group__input:checked:focus + .adyen-checkout__radio_group__label:before {
  box-shadow: 0 0 0 2px #06f6;
}

.adyen-checkout__radio_group__label.adyen-checkout__radio_group__label--invalid:before {
  border: 1px solid #d10244;
}

.adyen-checkout__checkbox {
  display: block;
}

.adyen-checkout__checkbox__label {
  color: #00112c;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding-left: 24px;
  font-size: .81em;
  font-weight: 400;
  line-height: 19px;
  display: inline-block;
  position: relative;
}

[dir="rtl"] .adyen-checkout__checkbox__label {
  padding-left: 0;
  padding-right: 24px;
}

.adyen-checkout__checkbox__input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label:before {
  opacity: 1;
}

.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label:after {
  background-color: #06f;
  border: 1px solid #06f;
}

.adyen-checkout__checkbox__input:checked:hover + .adyen-checkout__checkbox__label:after {
  border-color: #06f;
  box-shadow: 0 0 0 2px #06f6;
}

.adyen-checkout__checkbox__input:focus + .adyen-checkout__checkbox__label:after {
  border: 1px solid #06f;
  box-shadow: 0 0 0 2px #99c2ff;
}

.adyen-checkout__checkbox__input:hover:not(:focus) + .adyen-checkout__checkbox__label:after {
  border-color: #99a3ad;
  box-shadow: 0 0 0 2px #d4d9db;
}

.adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:before {
  content: "";
  height: 11px;
  opacity: 0;
  transform-origin: 100% 100%;
  width: 6px;
  z-index: 1;
  border: 2px solid #fff;
  border-top: 1px solid #0000;
  border-left: 1px solid #0000;
  border-radius: 0 2px 1px;
  transition: opacity .2s ease-out;
  position: absolute;
  top: 2px;
  left: 1px;
  transform: rotate(37deg);
}

[dir="rtl"] .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:before {
  left: auto;
  right: 8px;
}

.adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:after {
  content: "";
  height: 16px;
  width: 16px;
  z-index: 0;
  background-color: #fff;
  border: 1px solid #b9c4c9;
  border-radius: 3px;
  transition: background .15s ease-out, border 50ms ease-out, box-shadow .1s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

[dir="rtl"] .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:after {
  left: auto;
  right: 0;
}

.adyen-checkout__field--consentCheckbox {
  background: #e6e9eb;
  border: 1px solid #e6e9eb;
  border-radius: 6px;
  padding: 14px 14px 13px;
}

[dir="rtl"] .adyen-checkout__field--consentCheckbox {
  padding: 14px 14px 13px;
}

.adyen-checkout__field--consentCheckbox.adyen-checkout__field--error {
  border-color: #d10244;
}

.adyen-checkout__field--consentCheckbox .adyen-checkout-input__inline-validation {
  top: 10px;
  right: -27px;
}

.Select-module_adyen-checkout__dropdown__0Mj-n {
  position: relative;
}

.Select-module_adyen-checkout__dropdown__button__yTyqq {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Select-module_adyen-checkout__dropdown__button__yTyqq:after {
  content: "";
  height: 6px;
  width: 8px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='8' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.195 6.565a1 1 0 0 0 1.6 0l2.992-3.98a1 1 0 0 0-.8-1.602H1.013a1 1 0 0 0-.8 1.6l2.983 3.982Z' fill='%23687282'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  right: 16px;
}

[dir="rtl"] .Select-module_adyen-checkout__dropdown__button__yTyqq:after {
  left: 16px;
  right: auto;
}

.Select-module_adyen-checkout__dropdown__button--active__Ej-JR:after {
  transform: rotate(180deg);
}

.Select-module_adyen-checkout__filter-input__CwPBS {
  caret-color: #06f;
  color: #00112c;
  height: 100%;
  width: 100%;
  background: #fff;
  border: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
}

.Select-module_adyen-checkout__filter-input__CwPBS::-moz-placeholder {
  color: #b9c4c9;
  font-weight: 200;
}

.Select-module_adyen-checkout__filter-input__CwPBS:-ms-input-placeholder {
  color: #b9c4c9;
  font-weight: 200;
}

.Select-module_adyen-checkout__filter-input__CwPBS::placeholder {
  color: #b9c4c9;
  font-weight: 200;
}

.Select-module_adyen-checkout__filter-input__CwPBS:active, .Select-module_adyen-checkout__filter-input__CwPBS:focus {
  outline: 0;
}

.Select-module_adyen-checkout__dropdown__list__YtEzj {
  width: 100%;
  z-index: 1;
  background: #fff;
  margin: 0 0 50px;
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  overflow-y: auto;
}

.Select-module_adyen-checkout__dropdown__list__YtEzj.Select-module_adyen-checkout__dropdown__list--active__Gegw2 {
  display: block;
}

.Select-module_adyen-checkout__dropdown__element__ORU4- {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.adyen-checkout__image {
  opacity: 0;
  transition: opacity .6s ease-out;
}

.adyen-checkout__image--loaded {
  opacity: 1;
}

.adyen-checkout__dropdown {
  max-width: 100%;
  width: 100%;
  font-size: 1em;
}

.adyen-checkout__dropdown__button {
  color: #00112c;
  height: 40px;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  background: #fff;
  border: 1px solid #b9c4c9;
  border-radius: 6px;
  outline: 0;
  padding: 7px 24px 7px 12px;
  font-size: 1em;
  line-height: 20px;
  text-decoration: none;
  transition: border .2s ease-out, box-shadow .2s ease-out;
}

[dir="rtl"] .adyen-checkout__dropdown__button {
  padding: 7px 12px 7px 24px;
}

.adyen-checkout__dropdown__button:hover {
  border-color: #99a3ad;
}

.adyen-checkout__dropdown__button__icon {
  height: 26px;
  max-width: 40px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: 8px;
}

.adyen-checkout__dropdown__button--active, .adyen-checkout__dropdown__button--active:hover, .adyen-checkout__dropdown__button:active, .adyen-checkout__dropdown__button:focus {
  border-color: #06f;
  box-shadow: 0 0 0 2px #99c2ff;
}

.adyen-checkout__dropdown__button--readonly, .adyen-checkout__dropdown__button--readonly--active, .adyen-checkout__dropdown__button--readonly:focus, .adyen-checkout__dropdown__button--readonly:hover {
  color: #00112c;
  cursor: not-allowed;
  background: #e6e9eb;
  border-color: #0000;
}

.adyen-checkout__dropdown__button--readonly:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='8' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.195 6.565a1 1 0 0 0 1.6 0l2.992-3.98a1 1 0 0 0-.8-1.602H1.013a1 1 0 0 0-.8 1.6l2.983 3.982Z' fill='%23B9C4C9'/%3E%3C/svg%3E");
}

.adyen-checkout__dropdown__button--invalid {
  border-color: #d10244;
}

.adyen-checkout__dropdown__button--valid {
  border-bottom-color: #0abf53;
}

.adyen-checkout__dropdown__button__text {
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.adyen-checkout__dropdown__list {
  max-height: 375px;
  z-index: 2;
  border-radius: 6px;
  box-shadow: 0 2px 7px #000f2d4d;
}

.adyen-checkout__dropdown__list.adyen-checkout__dropdown__list--active {
  margin-top: 2px;
}

.adyen-checkout__dropdown__element {
  cursor: pointer;
  -webkit-hyphens: auto;
  hyphens: auto;
  -webkit-user-select: none;
  user-select: none;
  word-break: break-word;
  border: 1px solid #0000;
  outline: 0;
  padding: 8px;
  font-size: .81em;
  line-height: 20px;
  transition: background .2s ease-out, border-color .2s ease-out;
}

.adyen-checkout__dropdown__element:last-child {
  border-bottom: 0;
}

.adyen-checkout__dropdown__element:active, .adyen-checkout__dropdown__element:focus, .adyen-checkout__dropdown__element:hover {
  background: #e6e9eb99;
}

.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active {
  background: #0066ff1a;
}

.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active:active, .adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active:focus, .adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active:hover {
  background: #0066ff26;
}

.adyen-checkout__dropdown__element--disabled {
  cursor: not-allowed;
  opacity: .4;
}

.adyen-checkout__dropdown__element__icon {
  max-height: 26px;
  max-width: 40px;
  border-radius: 3px;
  margin-right: 8px;
}

.adyen-checkout__dropdown__element__flag {
  max-height: 18px;
  max-width: 27px;
  margin-left: 8px;
  margin-right: 10px;
}

.adyen-checkout__dropdown + .adyen-checkout-input__inline-validation {
  right: 32px;
}

.adyen-checkout__field-wrapper {
  width: 100%;
  display: flex;
}

.adyen-checkout__field--20 {
  width: 20%;
}

.adyen-checkout__field--30 {
  width: 30%;
}

.adyen-checkout__field--40 {
  width: 40%;
}

.adyen-checkout__field--50 {
  width: 50%;
}

.adyen-checkout__field--60 {
  width: 60%;
}

.adyen-checkout__field--70 {
  width: 70%;
}

.adyen-checkout__field--80 {
  width: 80%;
}

.adyen-checkout__field--col-70 {
  width: calc(70% - 8px);
}

.adyen-checkout__field--col-30 {
  width: calc(30% - 8px);
}

.adyen-checkout__field--col-50 {
  width: calc(50% - 8px);
}

.adyen-checkout__field-wrapper > .adyen-checkout__field:first-child {
  margin-right: 8px;
}

[dir="rtl"] .adyen-checkout__field-wrapper > .adyen-checkout__field:first-child {
  margin-left: 8px;
  margin-right: 0;
}

.adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
  margin-left: 8px;
}

[dir="rtl"] .adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
  margin-left: 0;
  margin-right: 8px;
}

.adyen-checkout__field-wrapper:last-of-type > .adyen-checkout__field {
  margin-bottom: 0;
}

.adyen-checkout__input {
  caret-color: #06f;
  color: #00112c;
  height: 40px;
  width: 100%;
  background: #fff;
  border: 1px solid #b9c4c9;
  border-radius: 6px;
  outline: none;
  padding: 5px 8px;
  font-family: inherit;
  font-size: 1em;
  transition: border .2s ease-out, box-shadow .2s ease-out;
  display: block;
  position: relative;
}

.adyen-checkout__input:hover {
  border-color: #99a3ad;
}

.adyen-checkout__input:required {
  box-shadow: none;
}

.adyen-checkout__input--disabled, .adyen-checkout__input[readonly] {
  background: #e6e9eb;
  border-color: #e6e9eb;
}

.adyen-checkout__input--disabled:hover {
  border-color: #e6e9eb;
}

.adyen-checkout__input-wrapper {
  display: block;
  position: relative;
}

.adyen-checkout__input-wrapper--block {
  display: block;
}

.adyen-checkout-input__inline-validation {
  height: 16px;
  width: 16px;
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

[dir="rtl"] .adyen-checkout-input__inline-validation {
  left: 14px;
  right: auto;
}

[dir="ltr"] .adyen-checkout-input__inline-validation {
  left: auto;
  right: 14px;
}

.adyen-checkout-input__inline-validation--valid {
  color: #0abf53;
}

.adyen-checkout-input__inline-validation--invalid {
  color: #d10244;
}

.adyen-checkout__input--valid {
  border-bottom-color: #0abf53;
}

.adyen-checkout__input--error, .adyen-checkout__input--error:hover, .adyen-checkout__input--invalid, .adyen-checkout__input--invalid:hover {
  border-color: #d10244;
}

.adyen-checkout__input::-moz-placeholder {
  color: #707070;
  font-weight: 200;
}

.adyen-checkout__input:-ms-input-placeholder {
  color: #707070;
  font-weight: 200;
}

.adyen-checkout__input::placeholder {
  color: #707070;
  font-weight: 200;
}

.adyen-checkout__input--date {
  padding-right: 30px;
}

.adyen-checkout__input--focus, .adyen-checkout__input--focus:hover, .adyen-checkout__input:active, .adyen-checkout__input:active:hover, .adyen-checkout__input:focus, .adyen-checkout__input:focus:hover {
  border: 1px solid #06f;
  box-shadow: 0 0 0 2px #99c2ff;
}

.adyen-checkout__input[readonly], .adyen-checkout__input[readonly]:hover {
  color: #687282;
  cursor: default;
  background-color: #e6e9eb;
  border-color: #0000;
}

.adyen-checkout__fieldset--personalDetails .adyen-checkout__field--gender .adyen-checkout__radio_group {
  display: flex;
}

.adyen-checkout__fieldset--personalDetails .adyen-checkout__radio_group {
  margin: 8px 0;
  display: flex;
}

.adyen-checkout__fieldset--personalDetails .adyen-checkout__radio_group__input-wrapper {
  margin-right: 20px;
}

.adyen-checkout__fieldset--personalDetails .adyen-checkout__radio_group__input-wrapper:last-child {
  margin: 0;
}

.adyen-checkout__open-invoice .adyen-checkout__fieldset--billingAddress {
  padding-bottom: 8px;
}

.adyen-checkout__open-invoice .adyen-checkout__fieldset--deliveryAddress {
  margin-top: 24px;
  padding-bottom: 8px;
}

.adyen-checkout__open-invoice .adyen-checkout__input--separateDeliveryAddress {
  margin-bottom: 0;
}

.adyen-checkout__open-invoice .adyen-checkout__field--consentCheckbox {
  margin-top: 22px;
}

.adyen-checkout__input--separateDeliveryAddress + .adyen-checkout__checkbox__label {
  margin-top: 16px;
}

.adyen-checkout__amazonpay__button {
  margin: auto;
}

.adyen-checkout__amazonpay .adyen-checkout__button--ghost {
  width: auto;
  margin: 8px auto 0;
  display: block;
}

@supports (-webkit-appearance:-apple-pay-button) {
  .ApplePayButton-module_apple-pay-button__l5g-d, .ApplePayButton-module_apple-pay__gYjuP {
    -webkit-appearance: -apple-pay-button;
  }

  .ApplePayButton-module_apple-pay-button__l5g-d {
    cursor: pointer;
    display: inline-block;
  }

  .ApplePayButton-module_apple-pay-button-black__istwW {
    -apple-pay-button-style: black;
  }

  .ApplePayButton-module_apple-pay-button-white__-wLaE {
    -apple-pay-button-style: white;
  }

  .ApplePayButton-module_apple-pay-button-white-with-line__MlRq7 {
    -apple-pay-button-style: white-outline;
  }

  .ApplePayButton-module_apple-pay-button--type-plain__ycfNl {
    -apple-pay-button-type: plain;
  }

  .ApplePayButton-module_apple-pay-button--type-buy__9m8AB {
    -apple-pay-button-type: buy;
  }

  .ApplePayButton-module_apple-pay-button--type-donate__HmRdK {
    -apple-pay-button-type: donate;
  }

  .ApplePayButton-module_apple-pay-button--type-check-out__XdGWd {
    -apple-pay-button-type: check-out;
  }

  .ApplePayButton-module_apple-pay-button--type-book__-v-VY {
    -apple-pay-button-type: book;
  }

  .ApplePayButton-module_apple-pay-button--type-subscribe__WxWIF {
    -apple-pay-button-type: subscribe;
  }

  .ApplePayButton-module_apple-pay-button--type-add-money__zeBA8 {
    -apple-pay-button-type: add-money;
  }

  .ApplePayButton-module_apple-pay-button--type-contribute__G3E8e {
    -apple-pay-button-type: contribute;
  }

  .ApplePayButton-module_apple-pay-button--type-order__ggI6j {
    -apple-pay-button-type: order;
  }

  .ApplePayButton-module_apple-pay-button--type-reload__QbgLd {
    -apple-pay-button-type: reload;
  }

  .ApplePayButton-module_apple-pay-button--type-rent__VzC-E {
    -apple-pay-button-type: rent;
  }

  .ApplePayButton-module_apple-pay-button--type-support__6EjmY {
    -apple-pay-button-type: support;
  }

  .ApplePayButton-module_apple-pay-button--type-tip__bdzGK {
    -apple-pay-button-type: tip;
  }

  .ApplePayButton-module_apple-pay-button--type-top-up__Eb3qR {
    -apple-pay-button-type: top-up;
  }
}

@supports not (-webkit-appearance:-apple-pay-button) {
  .ApplePayButton-module_apple-pay-button__l5g-d {
    box-sizing: border-box;
    max-height: 64px;
    min-height: 32px;
    min-width: 200px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 60%;
    border-radius: 5px;
    padding: 0;
    display: inline-block;
  }

  .ApplePayButton-module_apple-pay-button-black__istwW {
    background-color: #000;
    background-image: -webkit-named-image(apple-pay-logo-white);
  }

  .ApplePayButton-module_apple-pay-button-white-with-line__MlRq7, .ApplePayButton-module_apple-pay-button-white__-wLaE {
    background-color: #fff;
    background-image: -webkit-named-image(apple-pay-logo-black);
  }

  .ApplePayButton-module_apple-pay-button-white-with-line__MlRq7 {
    border: .5px solid #000;
  }
}

.adyen-checkout__applepay__button {
  height: 48px;
  width: 240px;
}

.adyen-checkout__dropin .adyen-checkout__applepay__button {
  width: 100%;
}

.adyen-checkout__issuer-button {
  cursor: pointer;
  height: 40px;
  background-color: #fff;
  border: none;
  border-radius: 6px;
  flex-grow: 2;
  flex-basis: 47%;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  font-size: .81em;
  transition: background .3s ease-out, box-shadow .3s ease-out;
  display: flex;
  box-shadow: inset 0 0 0 1px #b9c4c9;
}

.adyen-checkout__issuer-button:active {
  color: #000;
}

.adyen-checkout__issuer-button:not(.adyen-checkout__issuer-button--selected):focus, .adyen-checkout__issuer-button:not(.adyen-checkout__issuer-button--selected):focus-visible, .adyen-checkout__issuer-button:not(.adyen-checkout__issuer-button--selected):hover {
  outline: none;
  box-shadow: inset 0 0 0 2px #99a3ad;
}

.adyen-checkout__issuer-button--selected {
  color: #06f;
  height: 40px;
  background: #fff;
  font-weight: 500;
  transition: none;
  box-shadow: inset 0 0 0 2px #06f;
}

.adyen-checkout__issuer-button-img {
  max-height: 26px;
}

.adyen-checkout__issuer-button-group {
  flex-wrap: wrap;
  gap: 16px;
  display: flex;
}

.adyen-checkout__field--issuer-list-separator {
  color: #687282;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
}

.adyen-checkout__field--issuer-list-separator:after, .adyen-checkout__field--issuer-list-separator:before {
  content: "";
  height: 1px;
  width: 100%;
  background: #e6e9eb;
  display: block;
}

.adyen-checkout__field--issuer-list-separator:after {
  margin-left: 20px;
}

.adyen-checkout__field--issuer-list-separator:before {
  margin-right: 20px;
}

.adyen-checkout__field--issuer-list {
  margin-bottom: 0;
}

.adyen-checkout__card-input__form {
  transition: opacity .25s ease-out;
}

.adyen-checkout__card__cardNumber {
  max-width: 400px;
}

.adyen-checkout__card__cardNumber__input {
  padding: 5px 8px;
}

.adyen-checkout__card__exp-date__input--oneclick {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  line-height: 30px;
  overflow: hidden;
}

.adyen-checkout__card__holderName, .adyen-checkout__field--expiryDate, .adyen-checkout__field--storedCard {
  margin-bottom: 0;
}

.adyen-checkout__card-input .adyen-checkout__fieldset--billingAddress, .adyen-checkout__card__holderName, .adyen-checkout__card__kcp-authentication, .adyen-checkout__card__socialSecurityNumber, .adyen-checkout__installments, .adyen-checkout__store-details {
  margin-top: 16px;
}

.adyen-checkout-error-panel + .adyen-checkout__card__holderName, .adyen-checkout-error-panel--sr-only + .adyen-checkout__card__holderName, .adyen-checkout__card__holderName:first-child {
  margin: 0 0 16px;
}

.adyen-checkout__field--cardNumber .adyen-checkout__input--valid:not(.adyen-checkout__card__cardNumber__input--noBrand) + .adyen-checkout-input__inline-validation--valid, .adyen-checkout__field--cardNumber .adyen-checkout__input--error .adyen-checkout__card__cardNumber__brandIcon {
  display: none;
}

.adyen-checkout__field--securityCode.adyen-checkout__field--error .adyen-checkout__card__cvc__hint, .adyen-checkout__field--securityCode.adyen-checkout__field--valid .adyen-checkout__card__cvc__hint {
  opacity: 0;
}

@keyframes cvcIndicateLocation {
  0% {
    opacity: 1;
  }

  to {
    opacity: .3;
  }
}

.adyen-checkout__label--focused .adyen-checkout__field__cvc--back-hint .adyen-checkout__card__cvc__hint--back .adyen-checkout__card__cvc__hint__location, .adyen-checkout__label--focused .adyen-checkout__field__cvc--front-hint .adyen-checkout__card__cvc__hint--front .adyen-checkout__card__cvc__hint__location {
  animation-name: cvcIndicateLocation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.adyen-checkout__card__cvc__hint__wrapper {
  backface-visibility: visible;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  width: 27px;
  will-change: transform;
  align-items: center;
  margin: 0 10px;
  transition: transform .3s cubic-bezier(.455, .03, .515, .955);
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateZ(0);
}

.adyen-checkout__field__cvc--front-hint.adyen-checkout__card__cvc__hint__wrapper {
  transform: rotateY(180deg);
}

.adyen-checkout__card__cvc__hint {
  backface-visibility: hidden;
  transition: opacity .1s linear;
  position: absolute;
}

.adyen-checkout__card__cvc__hint--front {
  transform: rotateY(180deg);
}

@media (prefers-reduced-motion: reduce) {
  .adyen-checkout__card__cvc__hint__wrapper {
    transition: none;
  }
}

.adyen-checkout__fieldset--revolving-plan .adyen-checkout__fieldset__fields {
  justify-content: left;
}

.adyen-checkout__fieldset--revolving-plan .adyen-checkout__radio_group {
  flex-direction: column;
  display: flex;
}

.adyen-checkout__fieldset--revolving-plan .adyen-checkout__radio_group__input-wrapper {
  margin-top: 20px;
}

.adyen-checkout__fieldset--revolving-plan .adyen-checkout__field--revolving-plan-installments {
  width: 30%;
  margin-left: 15px;
  position: relative;
  top: 42px;
}

.LoadingWrapper-module_loading-input__form__ffCKa {
  opacity: 1;
}

.LoadingWrapper-module_loading-input__form--loading__7GmVo {
  opacity: 0;
}

.LoadingWrapper-module_loading-input__spinner__GxA51 {
  height: 100%;
  width: 100%;
  z-index: 1;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.LoadingWrapper-module_loading-input__spinner--active__ENNBS {
  display: block;
}

.CardInput-module_card-input__wrapper__wXSCw {
  position: relative;
}

.CardInput-module_card-input__wrapper__wXSCw *, .CardInput-module_card-input__wrapper__wXSCw :after, .CardInput-module_card-input__wrapper__wXSCw :before {
  box-sizing: border-box;
}

.CardInput-module_card-input__icon__3Cz5M {
  height: 18px;
  width: 27px;
  border-radius: 3px;
  margin-left: 7px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.CardInput-module_card-input__form__fRo1r {
  opacity: 1;
}

.CardInput-module_card-input__spinner__-j2Qi {
  height: 100%;
  width: 100%;
  z-index: 1;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.CardInput-module_card-input__spinner--active__slD7w {
  display: block;
}

.CardInput-module_card-input__form--loading__rrmdj {
  opacity: 0;
}

.CardInput-module_adyen-checkout__input__11tlB {
  max-height: 100px;
  display: block;
}

.CardInput-module_adyen-checkout__card__cvc__input--hidden__VIlHV, .CardInput-module_adyen-checkout__card__exp-date__input--hidden__evi6- {
  display: none;
}

.CardInput-module_adyen-checkout__card__exp-cvc__exp-date__input--hidden__YC3VT {
  justify-content: flex-end;
}

.CardInput-module_revolving-plan-installments__disabled__VhNj2 {
  opacity: .4;
  pointer-events: none;
}

.adyen-checkout-error-panel {
  margin-bottom: 20px;
}

.adyen-checkout-error-panel .adyen-checkout-error-panel__wrapper {
  font-size: .75em;
}

.adyen-checkout-error-panel--sr-only {
  height: 1px;
  width: 1px;
  position: absolute;
  top: auto;
  left: -10000px;
  overflow: hidden;
}

.adyen-checkout__card__dual-branding__buttons {
  opacity: .4;
  pointer-events: none;
  display: flex;
}

.adyen-checkout__card__dual-branding__buttons--active {
  opacity: 1;
  pointer-events: auto;
}

.adyen-checkout__card__dual-branding__buttons .adyen-checkout__card__cardNumber__brandIcon {
  cursor: pointer;
  opacity: 1;
}

.adyen-checkout__card__dual-branding__buttons .adyen-checkout__card__cardNumber__brandIcon:first-child {
  right: 40px;
}

.adyen-checkout__card__dual-branding__buttons .adyen-checkout__card__cardNumber__brandIcon--not-selected {
  opacity: .5;
}

.adyen-checkout__card__brands {
  flex-wrap: wrap;
  flex-shrink: 1;
  flex-basis: auto;
  gap: 4px;
  margin-top: -8px;
  margin-bottom: 16px;
  display: flex;
}

.adyen-checkout__card__brands img {
  height: 16px;
  width: 24px;
  border-radius: 3px;
}

.adyen-checkout__card__brands__brand-wrapper--disabled {
  opacity: .25;
}

.adyen-checkout__card__brands__brand-wrapper {
  height: 16px;
  width: 24px;
  transition: opacity .2s ease-out;
  display: inline-block;
  position: relative;
}

.adyen-checkout__card__brands__brand-wrapper:after {
  content: "";
  height: 100%;
  width: 100%;
  border: 1px solid #001b2b2b;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.adyen-checkout__button-group {
  background: none;
  justify-content: space-between;
  display: flex;
}

.adyen-checkout__button-group .adyen-checkout__button {
  color: #00112c;
  height: 40px;
  text-align: center;
  background: none;
  border: 0;
  margin-right: 8px;
  padding: 0;
  font-size: .81em;
  font-weight: 400;
  line-height: 40px;
  box-shadow: inset 0 0 0 1px #99a3ad;
}

.adyen-checkout__button-group .adyen-checkout__button:last-child {
  margin-right: 0;
}

.adyen-checkout__button-group .adyen-checkout__button:hover {
  background: none;
  box-shadow: inset 0 0 0 2px #99a3ad;
}

.adyen-checkout__button-group .adyen-checkout__button:active {
  background: #f7f8f9;
  box-shadow: inset 0 0 0 2px #99a3ad;
}

.adyen-checkout__button-group .adyen-checkout__button--disabled, .adyen-checkout__button-group .adyen-checkout__button--disabled:hover {
  cursor: not-allowed;
  opacity: .4;
  -webkit-user-select: none;
  user-select: none;
}

.adyen-checkout__button-group .adyen-checkout__button--selected, .adyen-checkout__button-group .adyen-checkout__button--selected:active, .adyen-checkout__button-group .adyen-checkout__button--selected:active:hover, .adyen-checkout__button-group .adyen-checkout__button--selected:hover {
  color: #06f;
  height: 40px;
  background: #e5efff;
  font-weight: 500;
  transition: none;
  box-shadow: inset 0 0 0 2px #06f;
}

.adyen-checkout__button-group .adyen-checkout__button .adyen-checkout__button-group__input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.adyen-checkout__adyen-giving .adyen-checkout__status__icon {
  margin: 56px auto 32px;
  display: block;
}

.adyen-checkout__adyen-giving .adyen-checkout__status__text {
  color: #00112c;
  text-align: center;
  margin-bottom: 56px;
}

.adyen-checkout__campaign {
  height: 227px;
  background: #00112c;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.adyen-checkout__campaign-link:hover .adyen-checkout__campaign-description {
  text-decoration: underline;
}

.adyen-checkout__campaign-container {
  height: 100%;
}

.adyen-checkout__campaign-logo {
  height: 48px;
  width: 48px;
  border: 2px solid #fff6;
  border-radius: 3px;
  margin-bottom: 16px;
  display: block;
  overflow: hidden;
}

.adyen-checkout__campaign-background-image {
  height: 100%;
  background-color: #00112c;
  background-position: 50%;
  background-size: cover;
}

.adyen-checkout__campaign-link .adyen-checkout__campaign-background-image:before {
  background: inherit;
  content: "";
  height: 100%;
  width: 100%;
  transition: transform .6s ease-out;
  position: absolute;
}

.adyen-checkout__campaign-link .adyen-checkout__campaign-background-image:hover:before {
  transform: scale(1.1);
}

.adyen-checkout__campaign-link .adyen-checkout__campaign-content {
  pointer-events: none;
}

.adyen-checkout__campaign-content {
  z-index: 2;
  padding: 16px;
  position: absolute;
  bottom: 0;
}

.adyen-checkout__campaign-description, .adyen-checkout__campaign-title {
  color: #fff;
  margin: 0;
  font-weight: 400;
}

.adyen-checkout__campaign-title {
  margin-bottom: 8px;
  font-size: 1em;
}

.adyen-checkout__campaign-description {
  font-size: .81em;
  line-height: 19px;
}

.adyen-checkout__adyen-giving-actions {
  margin-top: 16px;
}

.adyen-checkout__button.adyen-checkout__button--donate {
  margin: 16px auto 8px;
}

.adyen-checkout__button.adyen-checkout__button--decline {
  width: auto;
  margin: auto;
  display: block;
}

.adyen-checkout__paywithgoogle {
  height: 48px;
}

.adyen-checkout__paywithgoogle > div > button, .adyen-checkout__paywithgoogle > div > button.long, .adyen-checkout__paywithgoogle > div > button.short {
  height: 48px;
  transition: background-color .3s ease-out, box-shadow .3s ease-out;
}

.adyen-checkout__paywithgoogle > div > button.long:focus, .adyen-checkout__paywithgoogle > div > button.short:focus, .adyen-checkout__paywithgoogle > div > button:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #99c2ff;
}

.adyen-checkout__paywithgoogle > div > button.gpay-button {
  padding: 15px 24px 13px;
}

.adyen-checkout__econtext-input__field > .adyen-checkout__button--pay:only-child {
  margin-top: 0;
}

.adyen-checkout__voucher-result {
  box-sizing: border-box;
  text-align: center;
  border-radius: 12px;
  position: relative;
}

.adyen-checkout__voucher-result__bottom, .adyen-checkout__voucher-result__top {
  background: #fff;
  border: 1px solid #d4d9db;
}

.adyen-checkout__voucher-result__top {
  border-bottom: 0;
  border-radius: 12px 12px 0 0;
  padding: 40px 0 24px;
}

.adyen-checkout__voucher-result__bottom {
  border-top: 0;
  border-radius: 0 0 12px 12px;
}

.adyen-checkout__voucher-result__separator {
  height: 13px;
  width: calc(100% - 14px);
  background: #fff;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.adyen-checkout__voucher-result__separator:after, .adyen-checkout__voucher-result__separator:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBjbGlwLXBhdGg9InVybCgjYSkiPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJtMCAwIDUgMiAxLjUgNEg3VjBIMFptMCAxMyA1LTIgMS41LTRIN3Y2SDBaIiBmaWxsPSIjZmZmIi8+PHBhdGggZD0iTTYuNDIzIDYuNUM2LjQyMyAzLjMxMiAzLjc4My43NTYuNS41MThjMy4zODYuMjM2IDYgMi44NTUgNiA1Ljk4MiAwIDMuMTI3LTIuNjE0IDUuNzQ2LTYgNS45ODN2LS4wMDFjMy4yODQtLjIzNyA1LjkyMy0yLjc5NCA1LjkyMy01Ljk4MloiIHN0cm9rZT0iI0Q0RDlEQiIvPjxwYXRoIGZpbGw9IiNENEQ5REIiIGQ9Ik0wIDBoMXYxSDB6TTAgMTJoMXYxSDB6Ii8+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iYSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGg3djEzSDB6Ii8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+");
}

.adyen-checkout__voucher-result__separator:before {
  left: -7px;
}

.adyen-checkout__voucher-result__separator:after, .adyen-checkout__voucher-result__separator:before {
  content: "";
  height: 13px;
  width: 7px;
  background-position: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
}

.adyen-checkout__voucher-result__separator:after {
  right: -7px;
  transform: rotate(-180deg);
}

.adyen-checkout__voucher-result__separator__inner {
  width: 100%;
  border-top: 1px solid #e6e9eb;
}

.adyen-checkout__voucher-result__image {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
}

.adyen-checkout__link--voucher-result-instructions {
  display: inline-block;
}

.adyen-checkout__voucher-result__image__wrapper {
  height: 48px;
  margin: 0 24px;
  display: block;
  position: relative;
}

.adyen-checkout__voucher-result__image__wrapper:after {
  content: "";
  height: 100%;
  width: 100%;
  border: 1px solid #001b2b2b;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.adyen-checkout__voucher-result__image__wrapper:nth-child(2):before {
  content: "";
  height: 64px;
  width: 1px;
  border-left: 1px solid #d4d9db;
  position: absolute;
  top: -8px;
  left: -24.5px;
}

.adyen-checkout__voucher-result__image__brand, .adyen-checkout__voucher-result__image__issuer {
  height: 48px;
  border-radius: 3px;
}

.adyen-checkout__voucher-result__introduction {
  color: #00112c;
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
  font-size: .81em;
  line-height: 19px;
}

.adyen-checkout__voucher-result__amount {
  color: #00112c;
  text-align: center;
  margin: 24px auto 0;
  font-size: 1em;
  font-weight: 700;
}

.adyen-checkout__voucher-result__surcharge {
  color: #687282;
  text-align: center;
  font-size: .81em;
  font-weight: 400;
  line-height: 19px;
  display: block;
}

.adyen-checkout__voucher-result__code__label {
  -webkit-user-select: none;
  user-select: none;
  width: auto;
  margin: 0 auto;
  font-weight: 400;
  line-height: 19px;
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
}

.adyen-checkout__voucher-result__code__label:before {
  content: "";
  position: absolute;
}

.adyen-checkout__voucher-result__code__label__text {
  color: #00112c;
  letter-spacing: normal;
  background: #fff;
  padding: 0 8px;
  font-size: 13px;
  line-height: 1;
}

.adyen-checkout__voucher-result__code__barcode {
  height: 56px;
  max-width: 100%;
  -webkit-user-select: none;
  user-select: none;
  margin: 0 auto 8px;
  display: block;
}

.adyen-checkout__voucher-result__code {
  color: #00112c;
  letter-spacing: 1px;
  text-align: center;
  -webkit-user-select: all;
  user-select: all;
  width: 100%;
  word-break: break-word;
  border-width: 1px 0;
  margin: 0 auto;
  padding: 16px 48px;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.2;
  display: inline-block;
  position: relative;
}

.adyen-checkout__voucher-result__details {
  margin: -1px auto 0;
  padding: 0;
  list-style: none;
}

.adyen-checkout__voucher-result__details__item {
  color: #00112c;
  word-break: break-word;
  border-top: 1px solid #e6e9eb;
  justify-content: space-between;
  padding: 16px 24px;
  font-size: .81em;
  display: flex;
}

.adyen-checkout__voucher-result__details__item:last-child {
  margin-bottom: 0;
}

.adyen-checkout__voucher-result__details__label {
  max-width: 50%;
  text-align: left;
}

.adyen-checkout__voucher-result__details__value {
  max-width: 50%;
  text-align: right;
  font-weight: 700;
}

.adyen-checkout__voucher-result__actions {
  max-width: 100%;
  min-width: 200px;
  width: 300px;
  justify-content: center;
  align-items: center;
  margin: 0 auto 32px;
  padding: 0;
  list-style: none;
  display: flex;
}

.adyen-checkout__voucher-result__actions__item {
  margin: 0 4px;
}

.adyen-checkout__paypal__buttons {
  z-index: 0;
  position: relative;
}

.adyen-checkout__paypal__button {
  margin-bottom: 16px;
  display: flex;
}

.adyen-checkout__paypal__button:empty {
  display: none;
}

.adyen-checkout__paypal__status--pending {
  margin: 16px 0;
}

.adyen-checkout__paypal__status--processing {
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  font-size: 13px;
  display: flex;
}

.adyen-checkout__payment-method .adyen-checkout__paypal__status--pending {
  margin: -16px 0 38px;
}

.adyen-checkout__payment-method .adyen-checkout__paypal__status--processing {
  padding: 20px 0 65px;
}

.adyen-checkout__phone-input {
  direction: ltr;
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper {
  width: 100%;
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input {
  height: auto;
  padding: 0;
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input:focus {
  border: 1px solid #06f;
  box-shadow: 0 0 0 2px #99c2ff;
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button {
  height: 35px;
  width: auto;
  border: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button:after {
  box-sizing: revert;
  height: 10px;
  left: 40px;
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input--phoneNumber {
  height: 35px;
  border: 1px solid #0000;
  margin-left: 8px;
  padding-left: 15px;
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input-wrapper--phoneInput {
  align-items: center;
  display: flex;
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input-wrapper--phoneInput:focus {
  border: 1px solid #06f;
  box-shadow: 0 0 0 2px #99c2ff;
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__phoneNumber {
  width: 100%;
  align-items: center;
  margin-left: 65px;
  display: flex;
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__countryFlag {
  position: absolute;
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button--active, .adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button--active:hover {
  box-shadow: none;
}

.adyen-checkout__threeds2__challenge, .adyen-checkout__threeds2__challenge-container {
  box-sizing: border-box;
  height: inherit;
  min-height: 400px;
  width: 100%;
  background-color: #0000;
  display: block;
  position: relative;
  overflow: hidden;
}

.adyen-checkout__threeds2__challenge--01, .adyen-checkout__threeds2__challenge--01 .adyen-checkout__iframe--threeDSIframe {
  height: 400px;
  width: 250px;
}

.adyen-checkout__threeds2__challenge--02, .adyen-checkout__threeds2__challenge--02 .adyen-checkout__iframe--threeDSIframe {
  height: 400px;
  width: 390px;
}

.adyen-checkout__threeds2__challenge--03, .adyen-checkout__threeds2__challenge--03 .adyen-checkout__iframe--threeDSIframe {
  height: 600px;
  width: 500px;
}

.adyen-checkout__threeds2__challenge--04, .adyen-checkout__threeds2__challenge--04 .adyen-checkout__iframe--threeDSIframe {
  height: 400px;
  width: 600px;
}

.adyen-checkout__threeds2__challenge--05, .adyen-checkout__threeds2__challenge--05 .adyen-checkout__iframe--threeDSIframe {
  height: 100%;
  width: 100%;
}

.adyen-checkout__iframe--threeDSIframe {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.adyen-checkout__qr-loader {
  text-align: center;
  background: #fff;
  border: 1px solid #d4d9db;
  border-radius: 12px;
  padding: 40px;
}

.adyen-checkout__qr-loader--result {
  padding: 100px;
}

.adyen-checkout__qr-loader--app {
  border: 0;
  border-radius: 0;
  padding: 0;
}

.adyen-checkout__qr-loader__brand-logo {
  width: 74px;
  border-radius: 3px;
}

.adyen-checkout__qr-loader__subtitle {
  max-width: 400px;
  margin: 32px auto 0;
}

.adyen-checkout__qr-loader__subtitle--result {
  margin-bottom: 32px;
}

.adyen-checkout__qr-loader__payment_amount, .adyen-checkout__qr-loader__subtitle {
  color: #00112c;
  font-size: 1em;
  line-height: 19px;
}

.adyen-checkout__qr-loader__icon {
  height: 88px;
  width: 88px;
}

.adyen-checkout__qr-loader__payment_amount {
  font-weight: 700;
}

.adyen-checkout__qr-loader__progress {
  height: 4px;
  width: 152px;
  background: #d4d9db;
  border-radius: 24px;
  margin: 32px auto 12px;
  padding-right: 3%;
}

[dir="rtl"] .adyen-checkout__qr-loader__progress {
  padding-left: 3%;
  padding-right: 0;
}

.adyen-checkout__qr-loader__percentage {
  height: 100%;
  background: #06f;
  border-radius: 24px;
  display: block;
}

.adyen-checkout__qr-loader__countdown {
  color: #687282;
  font-size: .81em;
}

.adyen-checkout__qr-loader > .adyen-checkout__spinner__wrapper {
  margin: 60px 0;
}

.adyen-checkout__qr-loader__app-link {
  margin-top: 16px;
  display: none;
}

.adyen-checkout__qr-loader__separator__label {
  color: #687282;
  text-align: center;
  z-index: 1;
  font-size: 13px;
  display: block;
  position: relative;
  overflow: hidden;
}

.adyen-checkout__qr-loader__separator__label:after, .adyen-checkout__qr-loader__separator__label:before {
  content: " ";
  height: 1px;
  width: 50%;
  background-color: #e6e9eb;
  position: absolute;
  top: 51%;
  overflow: hidden;
}

.adyen-checkout__qr-loader__separator__label:before {
  text-align: right;
  margin-left: -52%;
}

.adyen-checkout__qr-loader__separator__label:after {
  margin-left: 2%;
}

.adyen-checkout__button.adyen-checkout__button--qr-loader {
  margin-top: 24px;
  text-decoration: none;
}

.adyen-checkout__qr-loader__instructions {
  color: #687282;
  margin-top: 32px;
  font-size: 1em;
  line-height: 1.5;
}

.adyen-checkout__qr-loader__actions {
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  display: flex;
}

@media only screen and (max-device-width: 1200px) {
  .adyen-checkout__qr-loader__app-link {
    display: block;
  }
}

.adyen-checkout__voucher-result--boletobancario .adyen-checkout__voucher-result__code, .adyen-checkout__voucher-result--oxxo .adyen-checkout__voucher-result__code {
  word-break: break-all;
  padding: 24px;
  font-size: .81em;
  line-height: 19px;
}

.adyen-checkout__alert-message {
  text-align: left;
  border-radius: 6px;
  margin: 0 0 16px;
  padding: 12px;
  font-size: .81em;
  display: flex;
}

.adyen-checkout__alert-message--error {
  background: #fbe6ed;
}

.adyen-checkout__alert-message--warning {
  background: #ffeacc;
}

.adyen-checkout__alert-message--info {
  background: #e5efff;
}

.adyen-checkout__alert-message__icon {
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.adyen-checkout__giftcard-result__header {
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  font-weight: 400;
  display: flex;
  position: relative;
}

.adyen-checkout__giftcard-result__header__title {
  align-items: center;
  display: flex;
}

.adyen-checkout__giftcard-result__name {
  margin-left: 8px;
}

.adyen-checkout__giftcard-result__balance {
  margin: 16px 0 0;
  padding: 0;
  list-style: none;
}

.adyen-checkout__giftcard-result__balance__item {
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
}

.adyen-checkout__giftcard-result__balance__item .adyen-checkout__giftcard-result__balance__title--transactionLimit {
  color: #687282;
}

.adyen-checkout__giftcard-result__balance__item:last-child {
  margin-bottom: 0;
}

.adyen-checkout__giftcard-result__balance__value--amount {
  font-weight: 700;
}

.adyen-checkout__giftcard-result__remaining-balance {
  color: #687282;
  text-align: center;
  margin: 8px auto 0;
  font-size: 13px;
  line-height: 19px;
}

.DropinComponent-module_adyen-checkout__payment-methods-list__mAjAm {
  margin: 0;
  padding: 0;
  list-style: none;
}

.DropinComponent-module_adyen-checkout__payment-method__nWdwg {
  max-height: 60px;
  display: block;
}

.DropinComponent-module_adyen-checkout__payment-method__details__-rsW7 {
  display: none;
}

.DropinComponent-module_adyen-checkout__payment-method__image__nB80V {
  height: 26px;
  width: 40px;
}

.DropinComponent-module_adyen-checkout__payment-method__image__wrapper__6NWzA {
  margin-right: 8px;
}

[dir="rtl"] .DropinComponent-module_adyen-checkout__payment-method__image__wrapper__6NWzA {
  margin-left: 8px;
  margin-right: 0;
}

.DropinComponent-module_adyen-checkout__payment-method--selected__6egZF {
  max-height: 100%;
}

.DropinComponent-module_adyen-checkout__payment-method--selected__6egZF .DropinComponent-module_adyen-checkout__payment-method__details__-rsW7 {
  display: block;
}

.adyen-checkout__payment-method__disable-confirmation {
  color: #fff;
  max-height: 0;
  opacity: 0;
  background: #d10244;
  border-left: 1px solid #c70241;
  border-right: 1px solid #c70241;
  margin: 0 -17px;
  font-size: .81em;
  transition: opacity .15s ease-out, max-height .15s linear, margin-bottom .1s linear;
  overflow: hidden;
}

.adyen-checkout__payment-method__disable-confirmation.adyen-checkout__payment-method__disable-confirmation--open {
  max-height: 62px;
  opacity: 1;
  margin-bottom: 16px;
}

.adyen-checkout__payment-method__disable-confirmation__content {
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  display: flex;
}

.adyen-checkout__payment-method__disable-confirmation__buttons {
  display: flex;
}

.adyen-checkout__payment-method__disable-confirmation__button {
  color: #fff;
  cursor: pointer;
  height: auto;
  width: auto;
  background: #d10244;
  border: 1px solid #0000;
  border-radius: 6px;
  margin: 0 0 0 8px;
  padding: 8px;
  line-height: 14px;
  display: block;
}

.adyen-checkout__payment-method__disable-confirmation__button:hover, .adyen-checkout__payment-method__disable-confirmation__button:hover:focus {
  box-shadow: none;
  background: #b8023c;
}

.adyen-checkout__payment-method__disable-confirmation__button:active, .adyen-checkout__payment-method__disable-confirmation__button:hover:active {
  box-shadow: none;
  background: #9e0234;
}

.adyen-checkout__payment-method__disable-confirmation__button--remove, .adyen-checkout__payment-method__disable-confirmation__button--remove:disabled {
  border-color: #fff;
}

.adyen-checkout__payment-method__disable-confirmation__button--cancel, .adyen-checkout__payment-method__disable-confirmation__button--cancel:disabled {
  border-color: #0000;
}

.adyen-checkout__payment-method {
  cursor: pointer;
  width: 100%;
  background: #fff;
  border: 1px solid #e6e9eb;
  margin-top: -1px;
  transition: opacity .3s ease-out;
  position: relative;
}

.adyen-checkout__payment-method:focus {
  outline: 0;
}

.adyen-checkout__payment-method--selected + .adyen-checkout__payment-method, .adyen-checkout__payment-method:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-top: 0;
}

.adyen-checkout__payment-method--next-selected, .adyen-checkout__payment-method:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-bottom: 0;
}

.adyen-checkout__payment-method--loading {
  opacity: .2;
}

.adyen-checkout__payment-method--selected.adyen-checkout__payment-method--loading {
  opacity: .9;
}

.adyen-checkout__payment-method--confirming .adyen-checkout__payment-method__details__content, .adyen-checkout__payment-method--disabling {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.adyen-checkout__payment-method--disabling {
  opacity: .3;
}

.adyen-checkout__payment-method__header {
  color: #00112c;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 12px 44px;
  font-size: 1em;
  font-weight: 400;
  transition: background .1s ease-out;
  display: flex;
  position: relative;
}

[dir="rtl"] .adyen-checkout__payment-method__header {
  padding: 12px 44px 12px 12px;
}

.adyen-checkout__payment-method--standalone .adyen-checkout__payment-method__header {
  padding: 16px;
}

.adyen-checkout__payment-method__header__title {
  color: #00112c;
  cursor: pointer;
  max-width: 100%;
  background: none;
  border: none;
  flex-shrink: 0;
  align-items: center;
  margin-right: 16px;
  padding: 4px;
  font-size: 1em;
  font-weight: 400;
  display: flex;
}

[dir="rtl"] .adyen-checkout__payment-method__header__title {
  margin-left: 16px;
  margin-right: 0;
}

.adyen-checkout__payment-method__surcharge {
  color: #687282;
  margin-left: 5px;
}

.adyen-checkout__payment-method--selected {
  cursor: default;
  background: #f7f8f9;
  border: 1px solid #e6e9eb;
  border-radius: 12px;
  margin: 8px 0;
  transition: margin .15s cubic-bezier(.4, 0, .2, 1), opacity .3s ease-out;
}

.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__header {
  flex-wrap: wrap;
}

.adyen-checkout__payment-method__name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.adyen-checkout__payment-method__name--selected {
  font-weight: 500;
}

.adyen-checkout__payment-method__details {
  padding: 0 16px;
  position: relative;
}

.adyen-checkout__payment-method__details__content {
  margin: 0 0 16px;
}

.adyen-checkout__payment-method__image__wrapper {
  height: 26px;
  width: 40px;
  position: relative;
}

.adyen-checkout__payment-method__image__wrapper--outline:after {
  content: "";
  height: 100%;
  width: 100%;
  border: 1px solid #001b2b2b;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.adyen-checkout__payment-method__image {
  border-radius: 3px;
  display: block;
}

.adyen-checkout__payment-method__brands {
  height: 16px;
  text-align: right;
  flex-wrap: wrap;
  flex-shrink: 1;
  flex-basis: auto;
  margin: 4px 0;
  display: flex;
  overflow: hidden;
}

.adyen-checkout__payment-method__brands .adyen-checkout__payment-method__brand-number {
  color: #99a3ad;
  font-size: 13px;
}

.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__brands {
  height: auto;
  text-align: left;
  overflow: visible;
}

.adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper {
  height: 16px;
  width: 24px;
  margin-right: 4px;
  transition: opacity .2s ease-out;
  display: inline-block;
}

.adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper:last-child {
  margin: 0;
}

.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper {
  margin-bottom: 4px;
}

.adyen-checkout__payment-method__brands img {
  height: 16px;
  width: 24px;
}

.adyen-checkout__payment-method__image__wrapper--disabled {
  opacity: .25;
}

.adyen-checkout__payment-method__radio {
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #b9c4c9;
  border-radius: 50%;
  transition: border-color .2s ease-out, box-shadow .2s ease-out;
  position: absolute;
  left: 16px;
}

[dir="rtl"] .adyen-checkout__payment-method__radio {
  left: auto;
  right: 16px;
}

.adyen-checkout__payment-method--standalone .adyen-checkout__payment-method__radio {
  display: none;
}

.adyen-checkout__payment-method__radio:after {
  content: "";
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 auto;
  transition: transform .3s ease-out;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%)scale(0);
}

.adyen-checkout__payment-method:hover:not(.adyen-checkout__payment-method--selected) .adyen-checkout__payment-method__radio {
  cursor: pointer;
  border-color: #99a3ad;
  box-shadow: 0 0 0 2px #d4d9db;
}

.adyen-checkout__payment-method__radio--selected {
  background-color: #06f;
  border: 0;
  transition: all .3s ease-out;
}

.adyen-checkout__payment-method__radio--selected:hover {
  box-shadow: 0 0 0 2px #06f6;
}

.adyen-checkout__payment-method__radio--selected:after {
  transform: translateY(-50%)scale(1);
}

.adyen-checkout__order-payment-methods-list {
  margin: 0 auto 16px;
  padding: 0;
  list-style: none;
}

.adyen-checkout__order-payment-method {
  width: 100%;
  background: #fff;
  border: 1px solid #e6e9eb;
  margin-top: -1px;
  position: relative;
}

.adyen-checkout__order-payment-method:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.adyen-checkout__order-payment-method:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.adyen-checkout__order-payment-method__header {
  color: #00112c;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  font-size: 1em;
  font-weight: 500;
  transition: background .1s ease-out;
  display: flex;
  position: relative;
}

.adyen-checkout__order-payment-method__header .adyen-checkout__payment-method__header__title {
  padding: 0;
}

.adyen-checkout__order-payment-method__details {
  padding: 0 16px 16px;
}

.adyen-checkout__order-payment-method__deducted-amount {
  justify-content: space-between;
  font-size: 1em;
  line-height: 1em;
  display: flex;
}

.adyen-checkout__order-payment-method__deducted-amount__label {
  font-size: .81em;
}

.adyen-checkout__order-payment-method__deducted-amount__value {
  font-weight: 500;
}

.adyen-checkout__order-remaining-amount {
  color: #7f4a00;
  width: 100%;
  background: #ffeacc;
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 8px 16px;
  font-size: .81em;
  display: block;
}

.adyen-checkout__order-remaining-amount strong {
  font-weight: 700;
}

.adyen-checkout__status {
  color: #00112c;
  height: 350px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d4d9db;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 32px;
  font-size: 1em;
  display: flex;
}

.adyen-checkout__status__icon {
  margin-bottom: 24px;
}

.adyen-checkout__status .adyen-checkout__spinner__wrapper {
  max-height: 88px;
}

.adyen-checkout__dropin, .adyen-checkout__dropin *, .adyen-checkout__dropin :after, .adyen-checkout__dropin :before {
  box-sizing: border-box;
}

.adyen-checkout__payment-methods-list--loading {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.adyen-checkout__instant-payment-methods-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.adyen-checkout__instant-payment-methods-list li:not(:last-child) {
  margin-bottom: 8px;
}

.adyen-checkout__link {
  color: #06f;
  text-decoration: none;
}

.adyen-checkout__link:hover {
  text-decoration: underline;
}

.AchInput-module_sf-input__wrapper__lfdiv {
  position: relative;
}

.AchInput-module_sf-input__wrapper__lfdiv *, .AchInput-module_sf-input__wrapper__lfdiv :after, .AchInput-module_sf-input__wrapper__lfdiv :before {
  box-sizing: border-box;
}

.AchInput-module_adyen-checkout__input__8WwCR {
  max-height: 100px;
  display: block;
}

.adyen-checkout__loading-input__form {
  transition: opacity .25s ease-out;
}

.adyen-checkout__pm__holderName {
  margin-bottom: 0;
}

.adyen-checkout__fieldset__title + .adyen-checkout__ach-sf__form {
  margin-top: 0;
}

.adyen-checkout__ach-input .adyen-checkout__fieldset--address, .adyen-checkout__ach-sf__form {
  margin-top: 16px;
}

.adyen-checkout__loading-input__form {
  transition: opacity .25s ease-out;
}

.adyen-checkout-phone-input--new {
  direction: ltr;
}

.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper {
  width: 100%;
}

.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__input {
  height: auto;
  padding: 0;
}

.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__input:focus-within {
  border: 1px solid #06f;
}

.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__input:focus-within .adyen-checkout-dropdown--countrycode-selector {
  border-right: 1px solid #06f;
}

.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button {
  height: 35px;
  width: auto;
  border: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button:after {
  box-sizing: revert;
  height: 10px;
}

.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button--active, .adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button--active:hover {
  box-shadow: none;
}

.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout-input--phone-number {
  height: 35px;
  min-height: 35px;
  border: 1px solid #0000;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
  line-height: 35px;
}

.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout-input--phone-number:focus-within {
  border: 1px solid #06f;
  box-shadow: 0 0 0 2px #99c2ff;
}

.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout-dropdown--countrycode-selector {
  min-width: 134px;
  width: 134px;
  border-right: 1px solid #dce0e5;
}

.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout-input-holder--phone-input {
  align-items: center;
  display: flex;
}

.adyen-checkout-phone-input--new .adyen-checkout__input-wrapper .adyen-checkout-phone-number {
  flex: 3;
  align-items: center;
  display: flex;
}

.adyen-checkout-phone-input--new .adyen-checkout-phone-input__error-holder {
  margin-top: -10px;
}

.adyen-checkout__await {
  text-align: center;
  background: #fff;
  border: 1px solid #d4d9db;
  border-radius: 12px;
  padding: 40px;
}

.adyen-checkout__await--result {
  padding: 100px;
}

.adyen-checkout__qr-loader--app {
  border: 0;
  border-radius: 0;
  padding: 0;
}

.adyen-checkout__await__brand-logo {
  width: 74px;
  border-radius: 3px;
}

.adyen-checkout__await__indicator-text, .adyen-checkout__await__subtitle {
  color: #00112c;
  margin-top: 32px;
  font-size: 1em;
  line-height: 19px;
}

.adyen-checkout__await__indicator-holder .adyen-checkout__await__indicator-text {
  margin-top: 6px;
  margin-left: 10px;
}

.adyen-checkout__await__indicator-holder {
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 20px;
  display: flex;
}

.adyen-checkout__await__subtitle--result {
  margin-bottom: 32px;
}

.adyen-checkout__await__icon {
  height: 88px;
  width: 88px;
}

.adyen-checkout__await__progress {
  height: 4px;
  width: 152px;
  background: #d4d9db;
  border-radius: 24px;
  margin: 32px auto 12px;
}

.adyen-checkout__await__percentage {
  height: 100%;
  background: #06f;
  border-radius: 24px;
  display: block;
}

.adyen-checkout__await__countdown {
  color: #687282;
  font-size: .81em;
}

.adyen-checkout__await > .adyen-checkout__spinner__wrapper {
  margin: 60px 0;
}

.adyen-checkout__await__app-link {
  margin-top: 16px;
  display: none;
}

.adyen-checkout__await__separator__label {
  color: #687282;
  text-align: center;
  z-index: 1;
  font-size: 13px;
  display: block;
  position: relative;
  overflow: hidden;
}

.adyen-checkout__await__separator__label:after, .adyen-checkout__await__separator__label:before {
  content: " ";
  height: 1px;
  width: 50%;
  background-color: #e6e9eb;
  position: absolute;
  top: 51%;
  overflow: hidden;
}

.adyen-checkout__await__separator__label:before {
  text-align: right;
  margin-left: -52%;
}

.adyen-checkout__await__separator__label:after {
  margin-left: 2%;
}

@media only screen and (max-device-width: 1200px) {
  .adyen-checkout__await__app-link {
    display: block;
  }
}

.adyen-checkout__blik__helper {
  color: #00112c;
  margin: 0 0 16px;
  padding: 0;
  font-size: 1em;
  font-weight: 400;
}

.adyen-checkout__bankTransfer__introduction {
  color: #00112c;
  margin: 0 0 16px;
  padding: 0;
  font-size: .81em;
  font-weight: 400;
}

.adyen-checkout__bankTransfer__emailField {
  margin: 0 0 16px;
}

.adyen-checkout__bacs--confirm {
  position: relative;
}

.adyen-checkout__bacs--confirm .adyen-checkout-input__inline-validation--valid {
  display: none;
}

.adyen-checkout__bacs .adyen-checkout__field--inactive {
  pointer-events: none;
}

.adyen-checkout__bacs .adyen-checkout__bacs--edit {
  cursor: pointer;
  width: 20%;
  position: absolute;
  top: -25px;
  right: 0;
}

.adyen-checkout__bacs .adyen-checkout__bacs--edit-dropin {
  top: -50px;
}

.adyen-checkout__bacs .adyen-checkout__bacs--edit .adyen-checkout__bacs--edit-button {
  color: #06f;
  cursor: pointer;
  text-align: end;
  background: none;
  border: none;
  text-decoration: underline;
}

.adyen-checkout__voucher-result__introduction {
  max-width: 420px;
  font-size: 1em;
}

.adyen-checkout__klarna-widget {
  pointer-events: all;
}

/*# sourceMappingURL=index.f411b5f5.css.map */
