* {
  margin: 0;
  padding: 0;
  font-size: inherit;
}
body {
  padding: 2rem;
}
body * + * {
  margin-top: 1rem;
}
